import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { mergeMapTo } from "rxjs/operators";
import "@firebase/messaging";
import { take } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { SessionService } from "./session.service";

@Injectable()
export class MessagingService {
  public currentMessage = new BehaviorSubject<any>(null);
  public alertCount = new BehaviorSubject<any>(null);
  public count = 0;

  currentData = this.currentMessage.asObservable();
  notificationCount = this.alertCount.asObservable();

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private sessionService: SessionService
  ) {}

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(() => {
      const data = {};

      data["deviceToken"] = token;
      this.angularFireDB.object("fcmTokens/").update(data);
    });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.sessionService.set("deviceToken", token);
        // this.updateToken( token);
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.onMessage((payload) => {
      if (payload) {
        this.count = this.count + 1;

        this.currentMessage.next(payload);
        this.alertCount.next(this.count);
      }

      navigator.serviceWorker
        .register("firebase-messaging-sw.js")
        .then(function (registration) {});
    });
  }

  resetCount() {
    this.alertCount.next(0);
  }
}

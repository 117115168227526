import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ExternalAuthguardService} from './services/externalguard.service';
import {AuthGuardService} from './services/authguard.service';
import {NotFoundComponent} from './modules/common/not-found/not-found.component';
import {AuthComponent} from './modules/auth/auth.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import { TrackLiveTrackComponent } from './modules/common/track-live-track/track-live-track.component';

const routes: Routes = [
  {path: '', redirectTo: '/auth/login', pathMatch: 'full'},
  {
    path: 'auth',
    canActivate: [ExternalAuthguardService],
    loadChildren: () =>
      import('./modules/auth/auth.module').then(mod => mod.AuthModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        mod => mod.DashboardModule
      )
  },
  {
    path: 'tracking',
    loadChildren: () =>
      import('./modules/common/track/track.module').
      then(mod => mod.TrackModule)
  },
  {
    path: 'truck-tracking',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./modules/common/track-live-track/track-live-track.module').
      then(mod => mod.TrackLiveTrackModule)
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./modules/common/reset-password/reset-password.module').then(mod => mod.ResetPasswordModule)
  },
  {
    path: '**',
    redirectTo: "/dashboard/home"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}]

})
export class AppRoutingModule {
}

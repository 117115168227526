import { Injectable } from "@angular/core";
import * as io from "socket.io-client";
//import {ApiService} from "./api.service";
import { environment } from "../../environments/environment";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  private url = environment.API_ENDPOINT;
  private socket;
  private accessToken;

  constructor(public sessionService: SessionService) {
    this.accessToken = this.sessionService.get("access_token");
    this.socket = io(this.url, {
      query: {
        accessToken: this.accessToken,
      },
      //autoConnect: true,
      timeout: 60000,
      reconnection: true,
    });
    this.socket.on("connect", () => {
      this.socket.io.opts.transports = ["polling", "websocket"];

      // 'G5p5...'
    });
    this.socket.on("respond", function (data) {});
    this.socket.on("disconnect", function () {
      console.log("Socket disconnected");
    });
    this.socket.on("error", () => {
      console.log("socket error");
    });
    this.socket.on("connect_error", (data) => {
      console.log("socket connect_error");
    });

    this.socket.on("latestLocation", (data) => {
      this.sessionService.onNewData(data);
    });
    this.socket.on("shipmentDocument", (data) => {
      this.sessionService.onNewshipmentDocument(data);
    });
    this.socket.on("userDocExpire", (data) => {
      this.sessionService.onNewuserDocExpire(data);
    });
    this.socket.on("customerRejected", (data) => {
      console.log("socket reject");
      this.sessionService.onCustomerRejected(data);
    });
  }

  setupSocketConnection() {
    this.socket = io(environment.API_ENDPOINT);
  }

  public sendMessage(message) {
    this.socket.emit("message", message);
  }

  ngOnDestroy() {
    this.socket.on("disconnect", function () {
      console.log("disconnected");
    });
  }
}

import { Injectable } from "@angular/core";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { Lightbox } from "ngx-lightbox";
import { ApiService } from "./api.service";
import { resolve } from "q";
import { FormControl } from "@angular/forms";

declare var google: any;

@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor(
    private toastr: ToastrService,
    private _lightbox: Lightbox,
    private api: ApiService
  ) {}
  private dataSource = new BehaviorSubject(Array);
  currentData = this.dataSource.asObservable();

  private driverdataSource = new BehaviorSubject<any>(null);
  driverCurrentData = this.driverdataSource.asObservable();

  private rejectCustomer = new BehaviorSubject<any>(null);
  rejectCustomerData = this.rejectCustomer.asObservable();

  private shipmentDocdataSource = new BehaviorSubject<any>(null);
  ShipmentCurrentData = this.shipmentDocdataSource.asObservable();
  private userDocExpiredataSource = new BehaviorSubject<any>(null);
  userDocExpiredCurrentData = this.userDocExpiredataSource.asObservable();

  private loaderSubject = new BehaviorSubject<any>(null);
  public loaderStatus = this.loaderSubject.asObservable();

  private showWarning = new BehaviorSubject<any>(null);
  public showStatus = this.showWarning.asObservable();

  warningOnLogout(showStatus) {
    this.showWarning.next(showStatus);
  }

  loaderOn(loaderStatus) {
    this.loaderSubject.next(loaderStatus);
  }

  get(key) {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    }

    return null;
  }

  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  setTouched(form) {
    Object.keys(form.controls).forEach((key) => {
      // if (form.controls[key].value.trim() === '') {
      //   form.controls[key].setValue('');
      // }
      // form.controls[key].value.trim();
      form.controls[key].markAsTouched({ onlySelf: true });
    });
  }

  added() {
    this.toastr.success("Added Successfully");
  }

  deleted() {
    this.toastr.success("Deleted Successfully");
  }

  send() {
    this.toastr.success("An OTP resent to your registered mobile number");
  }

  openLightBox(file) {
    let albums = [];
    if (file) {
      const album = {
        src: file,
        thumb: file,
      };
      albums.push(album);
      this._lightbox.open(albums, 0);
      // return true;
    }
  }
  checkImagePdf(file) {
    if (file) {
      if (file.size < 5000000) {
        //debugger
        if (
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "application/pdf"
        ) {
          return true;
        } else {
          this.toastr.error("Please upload jpg/png/pdf file only");
        }
      } else {
        this.toastr.error("Please upload image less than 5 MB");
      }
    }
    return false;
  }

  checkfileType(sFileName) {
    //console.log(sFileName);
    let _validFileExtensions;
    let blnValid;
    if (sFileName) {
      _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
      blnValid = false;
      for (let j = 0; j < _validFileExtensions.length; j++) {
        const sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          //console.log('returned', sCurExtension);
          return true;
        }
      }
    }
    return false;
  }

  checkDOCfileType(sFileName) {
    //console.log(sFileName);
    let _validFileExtensions;
    let blnValid;
    if (sFileName) {
      _validFileExtensions = [".doc", ".docx"];
      blnValid = false;
      for (let j = 0; j < _validFileExtensions.length; j++) {
        const sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          //console.log('returned', sCurExtension);
          return true;
        }
      }
    }
    return false;
  }

  checkTime() {
    const today = new Date();
    const curHr = today.getHours();

    if (curHr < 12) {
      return "Good Morning";
    } else if (curHr < 16) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  }

  convertTime12to24(time12h) {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  public noWhitespaceValidator(control: FormControl) {
    if (control.value != null) {
      const isWhitespace = (control.value || "").trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }
  }
  onNewData(data) {
    this.dataSource.next(data);
  }
  onNewshipmentDocument(data) {
    this.shipmentDocdataSource.next(data);
  }
  onCustomerRejected(data) {
    this.rejectCustomer.next(data);
  }
  onNewuserDocExpire(data) {
    this.userDocExpiredataSource.next(data);
  }
  onNewDriverData(data) {
    this.driverdataSource.next(data);
  }
  getAddressusingLatLong(lat1, long1) {
    return new Promise((resolve) => {
      let lat = parseFloat(lat1);
      let lng = parseFloat(long1);
      let latlng = new google.maps.LatLng(lat, lng);
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            resolve(results[1].formatted_address);

            // console.log(results[1])
            // return results[1].formatted_address;
            // alert("Location: " + results[1].formatted_address);
          }
        }
      });
    });
  }
  calculateOdometerDistance(lat1, lon1, lat2, lon2) {
    // lat1, lon1 = startLatlong
    // lat2, lon2 = currentLatlong
    let unit = "K";
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      let radlat1 = (Math.PI * lat1) / 180;
      let radlat2 = (Math.PI * lat2) / 180;
      let theta = lon1 - lon2;
      let radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }
}

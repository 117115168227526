export const apiConstants = {
  VALIDATE: {
    VALIDATING_USER: {
      URL: "/user/validateToken",
    },
  },
  AUTH: {
    LOGIN: {
      URL: "/user/signIn",
      METHOD: "post",
    },
    SIGNUP: {
      URL: "/user/signUp",
      METHOD: "post",
    },
    VERIFY_OTP: {
      URL: "/user/verifyOtp",
      METHOD: "post",
    },
    RESET_PASSWORD: {
      URL: "/user/forgotPassword",
      METHOD: "post",
    },
    LOGIN_VIA_ACCESS_TOKEN: {
      URL: "/user/accessTokenLogin",
      METHOD: "post",
    },
    RESEND_OTP: {
      URL: "/user/resendOtp",
      METHOD: "post",
    },
    GET_LANGUAGE: {
      URL: "/user/getLanguage",
    },
    RESET_PASSWORD_APP: {
      URL: "/resetPassword/changePasswordByToken",
    },
    TOKEN_EXPIRE: {
      URL: "/resetPassword/expireToken",
    },
    LOGOUT: {
      URL: "/user/logout",
    },
  },
  USER: {
    TRUCKS: {
      URL: "/user/getTruck",
      METHOD: "post",
    },
  },
  PROFILE: {
    URL: "/user/myProfile",
  },

  INQUIRY: {
    GETINQUIRY: {
      URL: "/user/webInquiryListing",
    },

    INQUIRYDETAILS: {
      URL: "/user/inquiryDetails",
    },
  },
  JOBS: {
    GETJOBS: {
      URL: "/user/webJobListing",
    },
    SEARCHJOB: {
      URL: "/user/searchViaJob",
    },
    JOBDETAILS: {
      URL: "/user/jobDetails",
    },
    TRACKING_STATUS: {
      URL: "/user/trackingStatus",
    },
    DRIVER_DOCUMENTS: {
      URL: "/user/assignDocuments",
    },
    DOWNLOAD_DOCUMENTS: {
      URL: "/user/driverDocPdf",
    },
    SHIPMENT_TYPE: {
      URL: "/user/getShipmentType",
    },

    UPLOAD_SHIPMENT_DOC: {
      URL: "/user/uploadShipmentDocumentByUser",
    },
    LIST_SHIPMENT_TYPE: {
      URL: "/user/getShipmentDocument",
    },
    EMAIL_JOBDETAIL: {
      URL: "/tracking/jobDetails",
    },
    EMAIL_TRACKING: {
      URL: "/tracking/trackingStatus",
    },
    DOCUMENTS_TRACKING: {
      URL: "/tracking/assignDocuments",
    },
  },
  HOME: {
    CALLBACK: {
      URL: "/user/requestCallBack",
    },
  },
  ASSIGNMENT: {
    RECENTLOCATIONS: {
      URL: "/user/recentLocations",
    },
    GETTRUCK: {
      URL: "/user/getTruck",
    },
    CREATEJOB: {
      URL: "/user/createJob",
    },
    CREATEINQUIRY: {
      URL: "/user/createInquiry",
    },
  },
  CALLBACK_LISTING: {
    URL: "/user/requestCallbackList",
  },
  NOTIFICATION: {
    ALL_LISTING: {
      URL: "/user/notificationList",
    },
    ACCEPT_REJECT: {
      URL: "/user/ackRejectQuote",
    },
    READ_NOTIFICATION: {
      URL: "/user/readNotification",
    },
    NOTIFICATION_COUNT: {
      URL: "/user/getNotificationCount",
    },
  },

  MAP: {
    TRACK_ASSET_LIVE: {
      URL: "/user/assignmentPathDetails",
    },
    TRACK_ASSET_LOGS: {
      URL: "/assets/activityTimeline",
    },
  },

  COMMON: {
    UPLOAD_IMAGE: {
      URL: "/dmsAdmin/uploadImage",
    },
    NOTIFICATIONS: {
      URL: "/transporter/notificationList",
    },

    CONTACT_US: {
      URL: "/transporter/ContactUs",
    },
  },
};
